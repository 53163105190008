.root {
    position: relative;
}

.container {
    margin: 0 auto;
    padding: 10px 40px;
    background-color: var(--primary-bg-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
}

.logo-container {
    display: flex;
    gap: 25px;
}

.title-container {
    display: flex;
    flex-direction: column;
}

.logo {
    max-height: 100%;
    width: auto;

    img {
        width: 50px;
        height: auto;
    }
}

.title {
    font-family: var(--primary-font);
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    color: var(--secondary-color);
}

.description {
    font-family: var(--primary-font);
    font-size: 14px;
    line-height: 20px;
}

.search-form {
    position: relative;
}

.search {
    font-family: var(--primary-font);
    padding: 15px 40px;
    background-color: #f4f4f4;
    border: 1px solid #bbbbbb;
    font-size: 16px;
    font-weight: 400;
    color: var(--secondary-color);
    border-radius: 10px;
    width: 100%;
}

.search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #000000;
    margin-right: 10px;
}

.navigation-container {
    display: flex;
    gap: 30px;
    align-items: center;
}

.navigation-bar {
    display: flex;
}

.navigation-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 66px;
    position: relative;

    &:hover {
        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: var(--third-bg-color);
            border-radius: 10px;
        }

        .navigation-text {
            background-color: rgba(128, 128, 128, 0.3);
            text-decoration: none;
        }
    }
}

.navigation-text {
    display: block;
    padding: 8px 16px;
    color: #ffffff;
    font-weight: 500;

    border-radius: 10px;
}