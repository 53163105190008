.root {
    width: 100%;
}

.container {
    width: 100%;
}

.general-info {
    display: flex;
    justify-content: space-around;
}

.info-item {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    background: var(--signed-block-color);
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    text-align: center;
}

.info-title {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
}

.info-data {
    font-size: 16px;
}

.table-container {
    display: flex;
    justify-content: space-between;
}

.table-block {
    width: 48%;
    height: 100%;
    
    &--loading {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.table-transaction {
    width: 48%;
    height: 100%;
    
    &--loading {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.table-block-title {
    font-size: 32px;
    font-weight: 700;
    padding: 30px 0;
    text-align: center;
}