.root {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    width: 100%;

    &--loading {
        width: fit-content;
    }
}

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-header {
    font-size: 18px;
    font-weight: 700;
    color: var(--secondary-color);
    font-family: var(--primary-font);

    &-item {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.table-cell {
    font-size: 16px;
    font-weight: 400;
    color: var(--secondary-color);
    font-family: var(--primary-font);
}

.search-form {
    position: relative;
    width: 60%;
}

.search {
    font-family: var(--primary-font);
    padding: 15px 40px;
    background-color: #f4f4f4;
    border: 1px solid #bbbbbb;
    font-size: 16px;
    font-weight: 400;
    color: var(--secondary-color);
    border-radius: 10px;
    width: 100%;
}

.search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
}

.table {
    width: 100%;
}

.row-even {
    background-color: rgba(128, 128, 128, 0.3);
}