:root {
    //color
    --primary-color: #ffffff;
    --secondary-color: #000000;
    --dark-color: #000000;
    --link-color: rgb(153, 105, 0);

    --primary-bg-color: #111111;
    --secondary-bg-color: #FEFEFE;
    --third-bg-color: #a62b2b;
    --light-bg-color: #ffffff;
    
    --signed-block-color: #13deb9;
    --missed-block-color: #fa896b;
    
    --kind-bg-color: #584ffb;
    --kind-color: #ffffff;
    --passed-bg-color: #22c55e;
    --passed-color: #ffffff;
    --reject-bg-color: #ef4444;
    --reject-color: #ffffff;
    --pending-bg-color: #f97316;
    --pending-color: #ffffff;
    --voting-bg-color: #0ea5e9;
    --voting-color: #ffffff;

    //font
    --primary-font: "HelloMissThang", sans-serif;
}