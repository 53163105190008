.root {
    position: relative;
    min-height: 100vh;
}

.background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    filter: blur(15px);
    
    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
}

.content-container {
    min-height: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    position: relative;
    z-index: 1;

    img {
        width: 100%;
        height: 400px;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        
        z-index: -1;
    }
}

.content {
    position: relative;
    width: calc(100% - 100px - 18px);
    min-height: 600px;
    display: flex;
    gap: 40px;
    background-color: var(--secondary-bg-color);
    border-radius: 5px;
    padding: 20px 40px 70px;
    justify-content: center;
    align-self: center;

    &--collapse {
        width: calc(100% - 100px - 18px);
    }
}

.title {
    font-size: 48px;
    margin: 40px 0;
    color: var(--primary-color);
    font-weight: 700;
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase;
    background-image: linear-gradient(
        -225deg,
        #231557 0%,
        #44107a 29%,
        #ff1361 67%,
        #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 5s linear infinite;
    display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}